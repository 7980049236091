.color-box {
  display: flex;
  align-items: center;
  /* padding: 1rem; */
  border-radius: 5px;
  /* margin-bottom: 1rem; */
}

.color-preview,
.color-swatches {
  width: 50%; /* Each takes half of the container */
  display: flex;
  flex-wrap: wrap;
}
.color-preview {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
}
.color-preview img {
  max-width: 50vw; /* Ensure image doesn't exceed its container */
  max-height: 42vw;
}

.color-swatch {
  /* width: calc(25.33% - 10px);  */
  max-width: 14vw;
  max-height: 11vw;
  height: auto;
  margin: 2%;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.color-swatch:hover {
  transform: scale(1.1);
}
.color-swatch-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 images in a row */
  grid-gap: 10px; /* Adjust spacing between images */
}
.color-swatch.selected {
  border: 2px solid black;
}
.color-text-heading{
  padding: 5%;
}
.color-property{margin-left: 5%;}
.paver-size{font-size: 4vw;}
.paver-size .available{font-size: 2vw; margin-left: 1.5vw; letter-spacing: 0.2em;}

@media screen and (max-width: 855px) and (min-width: 792px) {
  .paver-size{font-size: 4.7vw; }
  .paver-size .available{font-size: 2.0vw; margin-left: 1.5vw; letter-spacing: 0.25em;}
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .color-swatch {
    max-width: 12vw;
    max-height: 10vw;
  }
  .color-preview img {
    max-width: 47vw; 
    max-height: 42vw;
  }
}
@media only screen and (max-width: 500){
  .color-box {
    display: flex;
    align-items: center;
    border-radius: 5px;
  }
  .color-text{
    font-size: 3.0vw;
    letter-spacing: 0.1em;
}
}
