.banner {
    position: relative;
    overflow: hidden;
  }
  
  .banner img {
    width: 100%;
    /* height: 100vh; */
    /* height: 45vw; */
    aspect-ratio: 16/9;
    overflow: hidden;
    display: block;
    object-fit: cover;
  }

  
.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  border: none;
  padding: 10px; /* Adjust padding as needed */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker color on hover */
}

.left-arrow {
  left: 10px; /* Adjust the position as needed */
}

.right-arrow {
  right: 10px; /* Adjust the position as needed */
}
  
  .controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  
  .controls button {
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin: 0 5px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .controls button.active {
    background: white;
    color: black;
  }
  /* For desktop screens (larger than 768px) */
@media only screen and (min-width: 768px) {
  .banner img {
    aspect-ratio: 16/9;
  }
}

/* For tablet screens (between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner img {
    aspect-ratio: 4/3;  
  }
}

/* For mobile screens (up to 767px) */
@media only screen and (max-width: 767px) {
  .banner img {
    aspect-ratio: 9/16;
  }
}

/* For screens up to iPhone SE size (max-width: 767px) */
@media only screen and (min-width: 375px) and (max-width: 390px) { 
  .banner img {
    aspect-ratio: 21/20;
  }
}

@media only screen and (min-width: 390px) and (max-width: 430px) { 
  .banner img {
    aspect-ratio: 3/4;
  }
}

@media only screen and (min-width: 430px) and (max-width: 767px) { 
  .banner img {
    aspect-ratio: 3/4.08;
  }
}
