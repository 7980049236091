.porsche-social {
  width: 70%;
  margin-top: 52px;
  margin-bottom: 52px;
}

.porsche-social-header {
  font-size: 25px;
  font-family: 'PorscheNext bold';
  text-align: center;
  color: black;
  transform: translateX(1%);
}

.porsche-social-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 1px;
  grid-column-gap: 4px;
}

.porsche-social-media {
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.porsche-social-small {
  max-height: 132px;
  margin-top: 2%;
}

.porsche-social-large {
  max-height: 270px;
}

.porsche-social-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.porsche-social-item {
  position: relative;
  margin-bottom: -6px;
}

.porsche-social-item-logo {
  position: absolute;
  bottom: 5px;
  width: 35px;
  height: 35px;
  right: 0px;
}

.porsche-social-item-logo-1 {
  position: absolute;
  bottom: 4%;
  width: 35px;
  height: 35px;
  right: 1px;
}

@media screen and (max-width: 800px) {
  .container-center{
    position: relative;
    /* top: -84px; */
  }
  .porsche-social-header {
    margin-bottom: 25px;
  }
  .porsche-social-item-logo {
      bottom:4%; 
      width: 20px; 
      height: 20px; 
    }
  
  .porsche-social-item-logo-1 {
    bottom: 9%; 
    width: 16px; 
    height: 16px; 
  }
  .porsche-social-large {
    max-height: 116px;
  }
  .porsche-social-small {
    max-height: 56px;
  }
}
