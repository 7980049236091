.header {
  display: flex;
  justify-content: space-around;
  align-items: center; 
}

.modelImage,
.logoImage,
.contactImage {
  /* height: 18px;
  margin-top: 12px;  */
  height: 32px;
  margin-top: 2px;
  margin-left: 6%;
}

.logoImage {
  /* height: 23px;
  margin-top: 6px;  */
  height: 40px;
  margin-top: 4px;
}

@media screen and (max-width: 800px) {
  .header {
    margin-top: 2%;
  }

  .modelImage,
  .logoImage,
  .contactImage {
    margin-top: -5px; 
    margin-left: 0;
    width: 60%;
    height: 25px;
  }

  .modelImage {
    margin-left: 9%;
    height: 29px;
    width: 80%;
  }

  .logoImage {
    margin-left: 17%;
    height: 29px;
    width: 85%;
  }

  .contactImage {
    margin-left: 32px; 
    height: 29px;
    width: 70%;
  }
}
