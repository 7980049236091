.paversBackground{
    background:#000;
    color: #fff;
}

.category__heading{
  font-size: 24px;
  padding-top: 40px;
  letter-spacing: 1.4px;
  display: flex;
  width: 90%;
  padding-left: 3.2%;
  }

.category__title{
  font-weight: 500;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 30px;
  outline-offset: 2px;
  display: flex;
  width: 90%;
  padding-left: 3.2%;
  }

.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns, as we need 3 images + a potential gap */
    gap: 10px; /* Spacing between cards */
    justify-content: space-between;
    padding: 1.3%;
  }
  
  .product-card {
    grid-column: span 1;
    /* border: 1px solid;
    border-radius: 25px; */
    padding-bottom: 0px;
    width: 90%;
    margin-left: 4%;
    margin-top: 10%;
    cursor: pointer;
  }
  .product-card:hover{
    border: 1px solid;
    border-radius: 25px;
  }
  .product-card img{
    margin-left: 5%;
  }
  .product-text{
    padding-left: 9%;
    margin-top: 5%;
    font-size: 11px;
  }
.product-text p{
    font-size:15px;
    display: flex;
    width: 100%;
    color: #fff;
    line-height: 1%;
    margin-top: 5%;
    /* font-size: 20px; */
    font-size: 35px;
    margin-left: -14px !important;
}

.product-text p:nth-child(2) {
  line-height: 100%;
  margin-top: 8%;
}

  .product-grid-image{
    position: relative;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 90%;
    height: 75%;
    z-index: 0;
    margin-top: 7%;
  }

  @media screen and (max-width: 800px) {
    .product-grid{
      margin-top: 9%;
    }
    .product-text p{
      margin-top: 12%;
      font-size: 25px;

    }
    .product-text{
      padding-left: 23px;
    }
    .product-grid-image {
      height: 75%;
    }
    .product-card {
      padding-bottom: 7px;
      width: 93%;
      margin-left: 3%;
    }
  }

  @media screen and (max-width: 700px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }