.discover {
    width: 100%; /* Adjust width as needed */
    margin-top: 10px;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
  }

  
.discover-header {
    font-size: 25px;
    font-family: 'PorscheNext bold';
    text-align: center;
    color: black;
    margin-bottom: 4%;
}
  
  .discover-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .discover-card {
    grid-column: span 1;
    width: 80%;
    margin-left: 10%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .discover-card img {
    width: 250px;
    height: 255px;
    border-radius: 10px;
  }
  
  .discover-card-text {
    transform: translateX(-50%);
  }
  /* .discover-card-links {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 6px;
    gap: 6px;
  }
  
  .discover-card-links img {
    width: 6px;
    height: 12px;
  }
  
  .discover-card-links-text {
    margin: 0;
    font-family: 'PorscheNext Regular';
  } */
  .discover-card-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
  }
  
  .discover-card-links img {
    width: 6px;
    height: 12px;
  }
  
  .discover-card-links-text {
    margin: 0;
    font-family: 'PorscheNext Regular';
    color: black; /* Adjust color as needed */
  }
  .discover-card img:hover .discover-card-links-text {
    color: #d5001c;
  }
  
  .discover-card img:hover .discover-card-image {
    border-bottom: 3px solid #d5001c;
  }

@media screen and (max-width: 800px) {
  .discover-section{
    position: relative;
  }
  .discover-card img {
    width: 100px;
    height: 100px;
  }
}
  