.video-background {
  position: relative;
  width: 100%;
  height: 100vh; /* Set height to viewport height */
  overflow: hidden;
}

.video-background video {
  min-width: 100%; /* Ensure the video covers the entire viewport */
  min-height: 100%; /* Ensure the video covers the entire viewport */
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

.video-text {
  height: 270px;
  margin: 0 7% 25% 20%;
  filter: invert(100%);
  width: 430px;
}

.play-pause-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5); /*Adjust as needed */
    z-index: 999;
    transition: background-color 0.3s ease;
}

.play-pause-button:focus {
    outline: none;
}

.play-pause-button:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Adjust as needed */
}

.play-pause-button p-icon {
    color: #fff; /* Adjust icon color */
    font-size: 20px; /* Adjust icon size */
}

.play-pause-button.pause p-icon {
    transform: rotate(90deg); /* Rotate the pause icon to indicate pause state */
}

/* Small devices (portrait phones) */
@media screen and (max-width: 576px) {
  .video-text {
    width: 80%;
    height: auto;
    margin: 15% auto 4%;
  }
}

/* Medium devices (landscape phones, tablets) */
@media screen and (min-width: 577px) and (max-width: 768px) {
  .video-text {
    width: 70%;
    height: auto;
    margin: 15% auto 4%;
  }
}

/* Large devices (laptops, desktops) */
@media screen and (min-width: 769px) {
  .video-text {
    width: 430px;
    height: 270px;
    margin: 0 7% 25% 20%;
  }
}

/* Small devices (portrait phones) */
@media screen and (max-width: 576px) {
  .video-text {
    width: 80%;
    height: auto;
    margin: 15% auto 4%;
  }
}

/* Medium devices (landscape phones, tablets) */
@media screen and (min-width: 577px) and (max-width: 768px) {
  .video-text {
    width: 70%;
    height: auto;
    margin: 15% auto 4%;
  }
}

/* Large devices (laptops, desktops) */
@media screen and (min-width: 769px) {
  .video-text {
    width: 400px;
    height: 250px;
    margin: 0 0 -8% 12%;
  }
}

/* Additional responsive styles for screens up to 800px */
@media screen and (max-width: 800px) {
  .video-background {
    height: 100vh;
    top: 0; /* Reset top position */
  }

  .video-background video {
    top: 0;
    left: 0;
    transform: none; /* Reset transform */
  }
  .video-text {
    width: 50%;
    height: 50%;
    margin: 0 0 0 5%;
  }
}
