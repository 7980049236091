.nav_bottom ul {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    font-family: Futura;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    margin-bottom: 0 !important;
}

.nav_bottom {
    // position: absolute;
    bottom: 0px;
    width: 100%;
    height: 45px;
    display: flex;
    min-height: 45px;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background-color: black;
    color: #c7c7c7;
    line-height: 18px;
    letter-spacing: 1.8px;
}

.nav_bottom li {
    margin: 0 3px;
    font-size: 12px;
}
.footerLeft{
    width: 50%;
    margin-left:1% ;
    margin-top: 2%;
}
.footerRight{
    width: 45%;
    margin-left:53%;
    margin-top: 2%;
}
@media screen and (max-width: 800px) {
    .footerLeft{
        width: 90%;
        height: 75%;
        margin-left: 4%;
        margin-top: 7%;
    }
    .footerRight{
        width: 90%;
        height: 75%;
        margin-left: 0;
        margin-top: 7%;
    }
}
@media screen and (max-width: 500px) {
    .nav_bottom ul {
        display: flex;
        // flex-direction: column;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
        overflow-y: hidden;
        padding-left: 0;
        font-size: 14px !important;
        font-weight: 300;
        font-family: Futura;
    }


}