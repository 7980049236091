input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }

  /* Fallback for other browsers */ input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

.wall-connector-page{
    background-color: black;
    color: white;
    text-transform: uppercase;
}
.container{
    margin-left: 30px;
    margin-right: 20px;
}
  
.product-information{
    margin-top: 40px;
}
.product-image{
    margin: 30px 10px;
    border-bottom-right-radius: 5%;
    width: 100%;
    position: relative;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    height: auto;
    z-index: 0;
}
.small-image{
    width: 19%;
    display: inline-block;
    margin-left: 1%;
}


.wall-connector{
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.4px;
    margin-top: 10%;
}
.detail-text{
    margin-left: 30px;
}

.quantity-picker-button{
    background-color: transparent;
    border: none;
    color: white;
}

.btn-dark.tds-form-input-text {
    display: inline-block;
    margin: 4%;
    /* margin-right: 4%; */
    border-radius: 0; 
    background-color: #000;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    width: 80px;
    height: 50px;
  }
  
.addToCartBtn {
    background-color: hsla(0,0%,100%,.1); /* Adjust for desired color */
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    padding: 10px 20px;
    width: 100%; /* For full-width */
    margin-top: 5%;
    margin-bottom: 10%;
    cursor: pointer;
    letter-spacing: 1.2px;
    border-top: 2px solid #c7c7c7; /* Adjust color and thickness as needed */
    border-right: none;
    border-left: none;
    border-bottom: none;
    display: flex;
  }

  li, p{
    max-inline-size: 75ch;
  }
  
  .addToCartBtn:hover {
    background-color: #c7c7c7; /* Adjust for desired hover effect */
  }
  
.quantity{
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.8px;
    color: white;
}

.sub-heading{
    font-size: 12px;
    font-weight: 400;
    color: #c7c7c7;
    line-height: 24px;
    letter-spacing: 1.8px;
}
.local-electricity{
    font-size: 12px;
    color: hsla(0,0%,100%,.8);
    text-decoration-line: underline;
    margin-top: 15px;
    margin-bottom: 10px;
}
 .listing, p{
    font-size: 12px;
    font-weight: 400;
    color: #757575;
    color: #8e8e8e;
    line-height: 24px;
}
.link{
    font-size: inherit;
    font-weight: 400;
    color: hsla(0,0%,100%,.8);
    text-decoration-line: underline;
}
.link:hover{
    color: #393c41
}
.listing li{
    list-style-type: disc;
    text-align: left;
}
/* ul li::marker{
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;

} */
  .quantity-picker-container {
    display: flex;
    align-items: center;
  }
  ul{
    padding-left: 1rem;
  }

  @media screen and (max-width: 992px) {
    .small-image {
     display: none;
      
    }
    .addToCartBtn{
        width: 50%;
    }
  }
  @media screen and (max-width: 564px){
    .container{
      margin-left: -10px;
      margin-right: 0px;
    }
  }