.nav_bottom ul {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    margin-bottom: 0 !important;
}

.nav_bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 45px;
    display: flex;
    min-height: 45px;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background-color: white;
}

.nav_bottom li {
    margin: 0 3px;
}

@media screen and (max-width: 500px) {
    .nav_bottom ul {
        display: flex;
        // flex-direction: column;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
        overflow-y: hidden;
        padding-left: 0;
        font-size: 14px !important;
        font-weight: 300;
    }


}