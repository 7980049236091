.color-dimesion{
    display: flex;
    align-items: center;
    /* padding: 1rem; */
    border-radius: 5px;
    /* margin-bottom: 1rem; */
}
/* .color-dimesion-preview img {
    max-width: 100%; 
    max-height: 100%;
  } */
  .color-dimesion-preview img {
    max-width: 44vw; 
    /* max-height: 29vw; */
    max-height: 20vw;
}
.color-dimesion-preview {
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 50%;
}
.color-description{
    margin-bottom: 3%;
}

.color-text{
    /* font-size: 2vw; */
    font-size: 1.5vw;
    letter-spacing: 0.1em;
}

@media screen and (max-width: 500){
    .color-dimesion-preview img {
        max-width: 44vw; 
        max-height: 29vw;
    }
    .color-text{
        font-size: 3.0vw;
        letter-spacing: 0.1em;
    }
}
@media screen and (max-width: 500px) and (min-width: 200px) {
    .color-text{
        font-size: 2.8vw;
    }
}