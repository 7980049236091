.navbarStyle1{
  background-color: #000;
  height: 40px;
  position: fixed;
  top: 0;
  padding: 0 20px;
  z-index: 1000;
  width: 100%;
  display: 'flex',
}

@media screen and (max-width: 800px) {
  .navbarStyle1{
    padding: 0 5px;
  }
}
